export const color = {
  green: "#6bd994",
  purple: "#958ce1",
  blue: "#8cd3e1",
  orange: "#ffaa55",
  yellow: "#ffdd70",
  black: "#000000",
  charcoal: "#3c3d3e",
  white: "#eeeeee"
};
